import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import ThemeToggle from './themeToggle'


const MobileHeader = ({siteTitle}) => {
    return (
        <header className="mobile-header">
            <h1 className="app-logo">
                <Link to="/" >
                    {siteTitle}
                </Link>
            </h1>
            <ThemeToggle />
        </header>
    )
}

MobileHeader.propTypes = {
    siteTitle: PropTypes.string,
}
  
MobileHeader.defaultProps = {
    siteTitle: ``,
}

export default MobileHeader
