import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import SiteSettings from './siteSettings'
import Contact from './contact'
import WeatherWidget from './weather'
import { Navigation } from './navigation'

const Header = ({ siteTitle, siteDescription }) => {

return(
  <header className="page-header">
      <div className="title-description-wrapper">
      <h1 className="app-logo">
        <Link to="/" >
          {siteTitle}
        </Link>
      </h1>
      <div className="description">
        {siteDescription}
      </div>
    </div>
      <Navigation />
      <Contact />
      <WeatherWidget />
      {/* <SiteSettings /> */}
  </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
