import React, { useState} from 'react'
import emailjs from 'emailjs-com'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import data from '../data/app-config.json'

const emailConfig = data.emailConfig

const Contact = () => {
    const [contactOpen, setContactOpen] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)

    const disableSend = () => {
        document.getElementById('send_button').setAttribute("disabled", "true")
    }

    const resetForm = () => {
        document.getElementById('from_name').value = ''
        document.getElementById('from_email').value = ''
        document.getElementById('message_html').value = ''
        document.getElementById('send_button').removeAttribute("disabled")
    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(emailConfig.serviceId, emailConfig.emailTemplate, e.target, emailConfig.userId)
            .then(() => {
                setShowSuccess(true)
                resetForm()
                disableSend()
            }, (error) => {
                console.log(error.text);
                setShowError(true)
                resetForm()
            });
    }

    const toggleContactForm = () => {
        setContactOpen(!contactOpen)
    }
    return (
        <>
            <div className={`contact-form ${contactOpen ? 'active' : ''}`} onClick={() => toggleContactForm()}>
                <div className="contact-toggle-button">
                    <FontAwesomeIcon icon={faEnvelope} />
                </div>
            </div>
            {contactOpen && (
            <div className="contact-form-form">
                    
                <form className="contact-form" onSubmit={sendEmail}>
                        {showError ? (
                            <div className="alert alert-fail">
                                <div className="alert-message">
                                    There was an error sending your message.</div>
                                <button className="alert-dismiss" onClick={() => setShowError(false)}>x</button>
                            </div>) : null
                        }
                        {showSuccess ? (
                            <div className="alert alert-success">
                                <div className="alert-message">
                                    Message Sent. Thank You</div>
                                <button className="alert-dismiss" onClick={() => setShowSuccess(false)}>x</button>
                            </div>) : null}
                    <input type="hidden" name="contact_number" />
                    <div className="row">
                        <label htmlFor="from_name">Name</label>
                        <input type="text" name="from_name" required id="from_name" autoFocus />
                    </div>
                    <div className="row">
                        <label htmlFor="from_email">Email</label>
                        <input type="email" name="from_email" required id="from_email" />
                    </div>
                    <div className="row">
                        <label htmlFor="message_html">Message</label>
                        <textarea name="message_html" required id="message_html" />
                    </div>
                    <div className="row horizontal">
                        <input type="submit" value="Send" className="button button-primary" id="send_button" />
                        <input type="button" value="Reset" className="button button-link" onClick={() => resetForm()} />
                    </div>
                </form>
            </div>
            )}
        </>
    )
}

export default Contact
