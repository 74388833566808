import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    MobileView
  } from "react-device-detect";

import '../components/icons'

import data from '../data/pages.json'


const navList = data.pages

export const Navigation = () => {
    return (
        <nav className="site-nav">
            {navList.filter(links => links.id !== 0).map(link => (
                <Link key={link.id} to={link.to} activeClassName="current" className={`page-link ${link.classname}`} >
                    <MobileView>
                        <FontAwesomeIcon className="site-nav-icon" icon={link.icon} />
                    </MobileView>
                    {link.name}</Link>
            ))}
        </nav>
    )
}
