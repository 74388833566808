import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import Logger, { get } from 'js-logger'

import config from '../data/app-config.json'

const key = config.weatherConfig.apiKey
const url = config.weatherConfig.endpoint
const cityId = config.weatherConfig.cityID
let refreshTime = config.weatherConfig.refresh // in minutes - convert to ms for setInterval
refreshTime = refreshTime * 60000

const WeatherWidget = () => {
    const [currentWeather, setCurrentWeather] = useState(null)
    const [currentData, setCurrentData] = useState(null)

    const getWeather = () => {
        axios.get(`${url}data/2.5/weather?id=${cityId}&units=imperial&appid=${key}`)
        .then(resp => {
            Logger.log(resp)
            const weatherData = resp.data.weather[0]
            const mainData = resp.data.main
            setCurrentWeather(weatherData)
            setCurrentData(mainData)
            //Logger.log(`Weather Updated: ${moment().format()}`)
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    const setWeather = () => {
        getWeather();
    }

    const refreshWeather = () => {
        window.setInterval(getWeather, refreshTime)
    }

    useEffect(() => {
        Logger.useDefaults()
        setWeather()
        refreshWeather()
    }, [])

    if (currentWeather && currentData) {
    return (
        <div className="weather-widget">
            <div className="weather-title">My Weather</div>
            <div className="temp-current">{Math.round(currentData.temp)}&deg;</div>
            
            {/* <div className="current-conditions">
                    <div className="weather-icon"><img src={`https://openweathermap.org/img/wn/${currentWeather.icon}@2x.png`} title={currentWeather.description} alt={currentWeather.description} /></div>
            </div> */}
            <div className="weather-description">{currentWeather.main}</div> 
                {/* <div className="temp-high-low">
                    <div className="temp-high">H: {Math.round(currentData.temp_max)}&deg;</div>
                    <div className="temp-low">L: {Math.round(currentData.temp_min)}&deg;</div>
                </div> */}
            
            {/* {weatherInfo()}
            {weatherTemps()} */}
        </div>
    )
    }
    return null
}


export default WeatherWidget
