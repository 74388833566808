import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'

const ThemeToggle = () => {
    const [theme, setTheme] = useState('');
    
    const setLocalTheme = () => {
        if (theme !== 'light') {
            document.body.classList.remove('app-theme-dark')
            document.body.classList.add(`app-theme-light`)
            localStorage.setItem('theme', 'light')
            setTheme('light')
        }
        if ( theme !== 'dark') {
            document.body.classList.remove('app-theme-light')
            document.body.classList.add(`app-theme-dark`)
            localStorage.setItem('theme', 'dark')
            setTheme('dark')
        }
        
    }

    useEffect(() => {
        const setLocalTheme = () => {
            if (theme !== 'light') {
                document.body.classList.remove('app-theme-dark')
                document.body.classList.add(`app-theme-light`)
                localStorage.setItem('theme', 'light')
                setTheme('light')
            }
            if ( theme !== 'dark') {
                document.body.classList.remove('app-theme-light')
                document.body.classList.add(`app-theme-dark`)
                localStorage.setItem('theme', 'dark')
                setTheme('dark')
            }
            
        }

        const getLocalTheme = () => {
            let localTheme = localStorage.getItem('theme')
            if (localTheme !== null) {
                return setLocalTheme(localTheme)
            }
            return setLocalTheme('dark')
            
        }
        if (theme === '') {
            getLocalTheme()
        }
        
    }, [theme])

    

    const setDisable = (mode) => {
        if(theme === mode) {
            return 'active';
        }
        return '';
    }

    const darkModeMessage = () => {
        if (theme === 'light') {
            return 'Enable Dark Mode'
        }
        return 'Enable Light Mode'
    }

    const modeIcon = () => {
        if (theme === 'light') {
            return <FontAwesomeIcon icon={faSun} />
        }
        return <FontAwesomeIcon icon={faMoon} />
    }

    return (
        <div className="theme-toggle">
            <button className={`theme-toggle-item ${setDisable('dark')}`} onClick={() => setLocalTheme()} title={darkModeMessage()}>
                {modeIcon()}
            </button>
        </div>
    )
}

export default ThemeToggle
