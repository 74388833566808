import React from 'react'

import { Navigation } from './navigation'

const MobileMenu = () => {
    return (
        <div className="mobile-navigation">
            <Navigation />
        </div>
    )
}

export default MobileMenu
