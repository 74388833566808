/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  MobileView,
} from "react-device-detect"

import Header from './header'
import MobileHeader from './mobileHeader'
import MobileMenu from './mobileMenu'

const Layout = ({ children }) => {


  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <div className={`page-wrapper`}>
      <Header siteTitle={data.site.siteMetadata.title} siteDescription={data.site.siteMetadata.description} />
      <MobileView>
        <MobileHeader siteTitle={data.site.siteMetadata.title} />
      </MobileView>
        <main className={`page-content`}>
            {children}
        </main>
      <MobileView>
        <MobileMenu />
      </MobileView>
      
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
